export default class Stock {
    constructor(stockData, steps = 1, buyAmount = 1) {
        this.name = stockData.name;
        this.price = stockData.price;
        this.isin = stockData.isin;
        this.distributionYield = stockData.distributionYield;
        this.rate = stockData.rate;
        this.monthlyDividend = ((this.price * this.distributionYield) / 100) / 12;
        this.data = [];
        this.total = 0;
        this.sf = 0;
        this.calculateData(steps, buyAmount);
    }


    calculateData(steps, buyAmount) {
        const currentDate = new Date();
        let amount = 0;


        for (let i = 0; i < steps; i++) {
            let boughtAmount = 0;
            let sfCheck = false;
            let date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
            amount += parseInt(buyAmount);
            boughtAmount += parseInt(buyAmount);
            while (this.total >= this.price) {
                amount++;
                boughtAmount++;
                this.total -= this.price;
            }
            let rendite = this.calculateMonthReward(amount, date);
            this.total += rendite;
            if (rendite > this.price && this.sf === 0) {
                sfCheck = true;
                this.sf = i
            }
            const entry = {index: i, date: date, boughtAmount: boughtAmount, amount: amount, rendite: rendite, total: this.total, price: this.price, sfCheck: sfCheck};
            this.data.push(entry);
        }

    }


    calculateMonthReward(amount, date) {
        if (this.rate.includes(date.getMonth())) {
            return ((this.price * this.distributionYield) / 100) / 12 * amount;
        } else {
            return 0.00;
        }
    }

}