import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {router} from "../router";
import {Link} from "react-router-dom";
import React from "react";

export default function TopNavbar() {
    return (
        <Navbar className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="#home">HomeBook</Navbar.Brand>
                <Navbar.Toggle/>
                <Nav className="me-auto">
                    {router.map((route, index) => {
                            if (route.show) {
                                if(route.hasOwnProperty("children") && route.children.length > 0){
                                    return (
                                        <NavDropdown title={route.component} >
                                            {route.children.map((element, index) => {
                                                return (
                                                    <Link className={"nav-link"} to={element.path} key={index}>{element.component}</Link>
                                                )
                                            })}
                                        </NavDropdown>
                                    )
                                }else{
                                    return (
                                        <>
                                        <Link className={"nav-link"} to={route.path} key={index}>{route.component}</Link>
                                        </>
                                    )
                                }


                                // return (
                                //     <Link className={"nav-link"} to={route.path} key={index}>{route.component}</Link>
                                // )
                            }
                        }
                    )}
                </Nav>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        Signed in as: <a href="#login">Mark Otto</a>
                    </Navbar.Text>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}