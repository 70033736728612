import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {stocks} from "../../data/stocks";
import {useEffect, useState} from "react";
import Stock from "../../model/Stock";

export default function StockCalcualtion() {
    const [portfolio, setPortfolio] = useState({});
    const [portfolioList, setPortfolioList] = useState([])
    const [total, setTotal] = useState(0.00);
    const steps = 12;

    useEffect(() => (
        console.log(portfolioList)
    ), [portfolioList])

    const handleAmountChange = (e) => {
        const amount = parseInt(e.target.value);
        const stock = new Stock(stocks[e.target.id], steps, 1);
        let newPortfolio = portfolio;
        newPortfolio[stock.isin] = {stock: stock, amount: amount};
        if (amount <= 0) {
            delete newPortfolio[stock.isin]
        }
        setPortfolio(newPortfolio);
        calculate();
    }

    const calculate = () => {
        const newPortfolioList = [];
        let _total = 0.00;
        for (let i = 0; i < steps; i++) {
            let totalMonthReward = 0.00;
            let date = new Date(`${new Date().getFullYear()}-01-01`);
            date.setMonth(date.getMonth() + i);
            Object.entries(portfolio).forEach((item) => {
                const stock = item[1].stock;
                const amount = item[1].amount;
                const reward = stock.calculateMonthReward(amount, date)
                totalMonthReward += reward;
            })
            _total += totalMonthReward;
            newPortfolioList.push({"date": date, "totalMonthReward": totalMonthReward});
        }
        setTotal(_total)
        setPortfolioList(newPortfolioList)
    }

    const handleTest = () => {
        console.log(portfolio)
    }

    return (
        <>
            <h1>StockCalcualtion</h1>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title>Stocks</Card.Title>
                        </Card.Header>
                        <Card.Body className="">
                            <Table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Stock</th>
                                    <th>Amount</th>
                                </tr>
                                </thead>
                                <tbody>

                                {stocks.map((stock, index) => (
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td>{stock.name}</td>
                                        <td>
                                            <Form.Control type="number" id={`${index}`} placeholder="Amount" min={0} onChange={handleAmountChange} defaultValue={0}/>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>

                        </Card.Body>
                        <Card.Footer>
                            <Button onClick={handleTest}>Test</Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            Calculation
                        </Card.Header>
                        <Card.Body>
                            <Table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>monthly dividende in €</th>
                                </tr>
                                </thead>
                                <tbody>
                                {portfolioList.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{item.date.toLocaleDateString('de-DE', {year: 'numeric', month: "long"})}</td>
                                            {item.totalMonthReward > 0 ?
                                                <td>
                                                    {item.totalMonthReward.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}
                                                </td> : <td>
                                                    - €
                                                </td>
                                            }
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>Total</td>
                                    <td>{total.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</td>
                                </tr>
                                </tbody>

                            </Table>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </>
    );
}