import {Button, Card, Col, Form, FormSelect, Row, Tab, Table, Tabs} from "react-bootstrap";
import {stocks} from "../../data/stocks";
import {useEffect, useState} from "react";
import Stock from "../../model/Stock";
import {loadPortfolio, removeStockFromPortfolio, saveStockToPotfolio} from "../../service/PortfolioService";
import {Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

export default function Portfolio() {
    // const [portfolio, setPortfolio] = useState({});
    const [portfolioRewards, setPortfolioRewards] = useState([]);
    const [selectedStock, setSelectedStock] = useState(0);
    const [startAmount, setStartAmount] = useState(0);
    const [buyAmount, setBuyAmount] = useState(0);
    const [steps, setSteps] = useState(1);
    const [portfolio, setPortfolio] = useState({});
    const [totalPortfolio, setTotalPortfolio] = useState({
        stockAmount : 0,
        startAmount : 0,
        buyamount : 0,
        monthlyCosts : 0
    });


    useEffect(() => {
        const newPortfolio = loadPortfolio();
        setPortfolio(newPortfolio);
    }, []);

    const handleBuy = () => {
        const stock = new Stock(stocks[selectedStock]);
        const entry = {
            stock: stock,
            startAmount: startAmount,
            buyAmount: buyAmount,
            amount: startAmount,
            value: 0.00
        }
        if (startAmount === 0 && buyAmount === 0) return;

        // setPortfolio({...portfolio, [stock.isin]: entry});
        saveStockToPotfolio(entry);
        const newPortfolio = loadPortfolio();
        setPortfolio(newPortfolio);

    }


    const handleDelete = (isin) => {
        const newPortfolio = removeStockFromPortfolio(isin);
        setPortfolio(newPortfolio);
        // const portfolio = {...portfolio};
        // delete portfolio[isin];
        // setPortfolio(portfolio);
    }


    const resetPortfolio = () => {
        for (let [key, value] of Object.entries(portfolio)) {
            value.value = 0.00;
            value.amount = value.startAmount;
        }
    }


    const calculateMonthlyRendite = () => {
        resetPortfolio();
        let dataList = [];
        const currentDate = new Date();

        for (let i = 0; i < steps; i++) {
            let stockAmount = 0;
            let entry = {};
            let date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
            let total = 0;
            let monthlyCosts = 0;
            for (let [key, value] of Object.entries(portfolio)) {
                let amount = value.startAmount + (value.buyAmount * i);
                while (value.stock.price <= value.value) {
                    amount++;
                    value.value -= value.stock.price;

                }
                stockAmount += amount;
                const stock = new Stock(value.stock);
                const rendite = stock.calculateMonthReward(amount, date);
                value.value += rendite;
                monthlyCosts += value.stock.price * value.buyAmount
                total += rendite;
            }
            entry = {date: date, total: total, stockAmount: stockAmount, monthlyCosts: monthlyCosts};
            dataList.push(entry);

        }
        setPortfolioRewards(dataList);
        console.log(dataList);
    }


    useEffect(() => {
        calculateMonthlyRendite();

        let newTotalPortfolio = {stockAmount: 0, startAmount: 0, buyamount: 0, monthlyCosts: 0};
        for (let [key, value] of Object.entries(portfolio)) {
            newTotalPortfolio.stockAmount += value.startAmount + (value.buyAmount * steps);
            newTotalPortfolio.startAmount += value.startAmount;
            newTotalPortfolio.buyamount += value.buyAmount;
            newTotalPortfolio.monthlyCosts += value.stock.price * value.buyAmount;
        }
        setTotalPortfolio(newTotalPortfolio);

    }, [steps, portfolio]);


    return (
        <>
            <Card className={"mt-2"}>
                <Card.Header>
                    <Card.Title>
                        Add new stock to your portfolio
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form style={{textAlign: "left"}}>
                        <Row>
                            <Col>
                                <Form.Group className={"mb-3"} controlId={"amount"}>
                                    <Form.Label>Stock:</Form.Label>
                                    <Form.Select value={selectedStock} onChange={e => setSelectedStock(parseInt(e.target.value))}>
                                        {stocks.map((stock, index) => (
                                            <option key={index} value={index}>{stock.name} ({stock.price.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})})</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Form.Group className={"mb-3"} controlId={"amount"}>
                                    <Form.Label>Startamount:</Form.Label>
                                    <Form.Control type="number" placeholder="Amount" value={startAmount} onChange={e => setStartAmount(parseInt(e.target.value))} min={0}/>
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Form.Group className={"mb-3"} controlId={"amount"}>
                                    <Form.Label>Buyamount:</Form.Label>
                                    <Form.Control type="number" placeholder="Amount" value={buyAmount} onChange={e => setBuyAmount(parseInt(e.target.value))} min={0}/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <Button variant="success" onClick={handleBuy}>Buy</Button>

                </Card.Footer>
            </Card>
            <Card className={"mt-4"}>
                <Card.Header><Card.Title>Portfolio</Card.Title></Card.Header>
                <Card.Body>
                    <Tabs fill>
                        <Tab title={"Overview"} eventKey={"overview"}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Stock</td>
                                    <td>Startamount</td>
                                    <td>Buyamount</td>
                                    <td>Monthly costs</td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.entries(portfolio).map(([isin, entry], index) => {
                                    return (<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{entry.stock.name}</td>
                                        <td>{entry.startAmount}</td>
                                        <td>{entry.buyAmount}</td>
                                        <td>{(entry.stock.price * entry.buyAmount).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</td>
                                        <td>
                                            <Button id={entry.stock.isin} variant={"danger"} onClick={e => handleDelete(e.target.id)}>Delete</Button>
                                        </td>
                                    </tr>)
                                })}
                                <tr>
                                    <td></td>
                                    <td>{totalPortfolio.stockAmount}</td>
                                    <td>{totalPortfolio.startAmount}</td>
                                    <td>{totalPortfolio.buyamount}</td>
                                    <td>{totalPortfolio.monthlyCosts.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab title={"List"} eventKey={"list"}>
                            <FormSelect onChange={e => setSteps(parseInt(e.target.value))} className={"m-2"}>
                                {Array.from({length: 11}, (v, k) => k + 1).map((item, index) => (
                                    <option key={`month-${index}`} value={item}>{item} Monate</option>
                                ))}
                                {Array.from({length: 50}, (v, k) => k + 1).map((item, index) => (
                                    <option key={`year-${index}`} value={item * 12}>{item} Jahre</option>
                                ))}
                            </FormSelect>
                            <Table>
                                <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Stocks</td>
                                    <td>Monthly rendite</td>
                                    <td>Monthly costs</td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>
                                {portfolioRewards.map((entry, index) => {
                                    return (
                                        <tr  className={entry.total > entry.monthlyCosts ? "table-success" : ""}>
                                            <td>{entry.date.toLocaleDateString()} ({entry.date.getFullYear() - 1997})</td>
                                            <td>{entry.stockAmount}</td>
                                            <td>{entry.total.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</td>
                                            <td>{entry.monthlyCosts.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab title={"Chart"} eventKey={"chart"}>
                            <ResponsiveContainer width={"100%"}
                                                 height={600}
                            >
                                <ComposedChart
                                    data={portfolioRewards}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="date"/>
                                    <YAxis/>
                                    <Tooltip />
                                    <Line type="monotone" dataKey="monthlyCosts" stroke="#ff7300"/>
                                    <Area type="monotone" dataKey="total" stroke="#8884d8" fill="#8884d8"/>
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>

        </>
    );
}