const LOCALSTORAGE_KEY = 'portfolio';

export function createPortfolio() {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({}));
}

export function loadPortfolio() {
    if (localStorage.getItem(LOCALSTORAGE_KEY) === null) createPortfolio();
    let portfolio = localStorage.getItem(LOCALSTORAGE_KEY);
    return portfolio ? JSON.parse(portfolio) : {};
}

export function getFromPortfolio(stockId) {
    let stockPortfolio = loadPortfolio();
    return stockPortfolio[stockId];
}

export function saveStockToPotfolio(entry) {
    let portfolio = loadPortfolio();
    portfolio[entry.stock.isin] = entry;
    savePortfolio(portfolio);
}


export function savePortfolio(portfolio) {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(portfolio));
}

export function removeStockFromPortfolio(isin) {
    let portfolio = loadPortfolio();
    delete portfolio[isin];
    savePortfolio(portfolio);
    return loadPortfolio();
}


export function exportPortfolio() {
    let stockPortfolio = JSON.stringify(loadPortfolio());
    const dataBlob = new Blob([stockPortfolio], {type: "application/json"});
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.download = `${LOCALSTORAGE_KEY}.json`;
    link.href = url;
    link.click();
}

export function importPortfolio(importData) {
    if (importData) {
        const dataObj = JSON.parse(importData);
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(dataObj));
    }
}

export function resetPortfolio() {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    createPortfolio();
}