import './App.css';
import {Container} from "react-bootstrap";
import {HashRouter, Route, Routes} from "react-router-dom";
import TopNavbar from "./component/TopNavbar";
import {router} from "./router";

function App() {
    return (
        <div className="App">
            <HashRouter>
                <TopNavbar/>
                <Container>
                    <Routes>
                        {router.map((route, index) => (
                            <Route key={index} path={route.path} element={route.element}/>
                        ))}
                    </Routes>
                </Container>

            </HashRouter>
        </div>
    );
}

export default App;
