import {useState} from "react";
import StockCard from "../../component/StockCard";
import {Button, Col, Form, FormSelect, Row} from "react-bootstrap";
import {stocks} from "../../data/stocks";


export default function StockIndex() {
    const [selectedStock, setSelectedStock] = useState(0);
    const [steps, setSteps] = useState(1);
    const [buyAmount, setBuyAmount] = useState(1);

    const handleSelectChange = (event) => {
        setSelectedStock(event.target.value);
    }


    return (
        <div>
            <h1>Stock Index <Button size={"sm"} variant={"success"}>+</Button></h1>
            <Row>
                <Col>
                    <Form.Select value={selectedStock} onChange={handleSelectChange} className={"m-2"}>
                        {stocks.map((stock, index) => (
                            <option key={index} value={index}>{stock.name}</option>
                        ))}
                    </Form.Select>

                </Col>
            </Row>
            <Row>
                <Col>
                    <FormSelect value={steps} onChange={e => setSteps(e.target.value)} className={"m-2"}>
                        {Array.from({length: 11}, (v, k) => k + 1).map((item, index) => (
                            <option key={index} value={item}>{item} Monate</option>
                        ))}
                        {Array.from({length: 24}, (v, k) => k + 1).map((item, index) => (
                            <option key={index} value={item * 12}>{item} Jahre</option>
                        ))}
                    </FormSelect>
                </Col>
                <Col>
                    <Form>
                        <Form.Label>Stock per month: {buyAmount} </Form.Label>
                        <Form.Range onChange={e => setBuyAmount(parseInt(e.target.value))} min={1} max={10} value={buyAmount}/>
                    </Form>
                </Col>
            </Row>

            <StockCard stockData={stocks[selectedStock]} steps={steps} buyAmount={buyAmount}/>
        </div>
    );
}