import StockIndex from "./pages/stock/StockIndex";
import Portfolio from "./pages/stock/Portfolio";
import SubscriptionIndex from "./pages/subscription/SubscriptionIndex";
import StockCalcualtion from "./pages/stock/StockCalculation";

export const router = [
    {
        "component": "Home",
        "path": "/",
        "element": "<>Hallo</>"
    }, {
        "component": "Subscription",
        "path": "/subscriptions",
        "element": <SubscriptionIndex/>,
        "show": false,
    }, {
        "component": "Stocksindex",
        "path": "/stockindex",
        "element": <StockIndex/>,
        "show": false,
    }, {
        "component": "Stock calculation",
        "path": "/stockcalculation",
        "element": <StockCalcualtion/>,
        "show": false,
    }, {
        "component": "Portfolio",
        "path": "/portfolio",
        "element": <Portfolio/>,
        "show": false,
    },
    {
        "component": "Stocks",
        "show": true,
        // "path": "/stocks",
        "children": [
            {
                "component": "Portfolio",
                "path": "/portfolio",
                "element": <Portfolio/>,
                "show": true,
            },
            {
                "component": "Stocksindex",
                "path": "/stockindex",
                "element": <StockIndex/>,
                "show": true,
            }, {
                "component": "Stock calculation",
                "path": "/stockcalculation",
                "element": <StockCalcualtion/>,
                "show": true,
            }
        ],
    }
]