const JAN = 0;
const FEB = 1;
const MAR = 2;
const APR = 3;
const MAI = 4;
const JUN = 5;
const JUL = 6;
const AUG = 7;
const SEP = 8;
const OCT = 9;
const NOV = 10;
const DEC = 11;


const MONTHLY = [
    JAN, FEB, MAR,
    APR, MAI, JUN,
    JUL, AUG, SEP,
    OCT, NOV, DEC
];
const QUARTAL_1 = [JAN, APR, JUL, OCT];
const QUARTAL_2 = [FEB, MAI, AUG, NOV];
const QUARTAL_3 = [MAR, JUN, SEP, DEC];
const HALF_YEARLY = [JAN, JUL];

export const stocks = [
    {name: 'Samsung Electronics Co Ltd', isin: "US7960502018", price: 1087, distributionYield: 2.3, rate: [APR, MAI, AUG, NOV]},
    {name: 'LVMH Moet Hennessy Louis Vuitton SE', isin: "FR0000121014", price: 824.05, distributionYield: 1.53, rate: [APR, DEC]},
    {name: 'Realty Income', isin: 'US7561091049', price: 50.96, distributionYield: 5.64, rate: MONTHLY},
    {name: 'Amundi MSCI New Energy ESG Screened UCITS ETF', isin: 'FR0010524777', price: 20.64, distributionYield: 0.84, rate: [JUL]},
    {name: 'AGNC Investment', isin: "US00123Q1040", price: 9.06, distributionYield: 14.68, rate: MONTHLY},
    {name: 'Main Street Capital', isin: 'US56035L1044', price: 42.25, distributionYield: 6.29, rate: MONTHLY},
    {name: 'Armour Residential REIT', isin: "US0423155078", price: 18.60, distributionYield: 14.87, rate: MONTHLY},
    {name: 'iShares STOXX Europe 600 Banks UCITS ETF (DE)\n', isin: "DE000A0F5UJ7", price: 16.80, distributionYield: 4.81, rate: QUARTAL_1},
    {name: 'Banco Santander S.A.', isin: 'ES0113900J37', price: 3.81, distributionYield: 4.41, rate: [MAI, NOV]},
    {name: 'Vanguard FTSE All-World UCITS ETF (DIST)', isin: 'IE00B3RBWM25', price: 113.25, distributionYield: 2.26, rate: QUARTAL_3},

    // {name: 'Vanguard FTSE All-World UCITS ETF (Dist)', isin: "IE00B3RBWM25", price: 114, distributionYield: 1.95, rate: QUARTAL_2},
    // {name: 'STOXX Global Dividend 100 Swap EUR (Dist)', isin: "LU0292096186", price: 26.63, distributionYield: 6.00, rate: QUARTAL_1},
    // {name: 'SuperDividend USD (Dist)', isin: "IE00077FRP95", price: 8, distributionYield: 13.76, rate: MONTHLY},
    // {name: 'STOXX Global Dividend 100 EUR (Dist)', isin: "DE000A0F5UH1", price: 26.93, distributionYield: 6.18, rate: [APR]},
    {name: 'LTC Properties', isin: 'US5021751020', price: 29.25, distributionYield: 7.13, rate: MONTHLY},
    {name: 'Prospect Capital', isin: 'US74348T1025', price: 5.68, distributionYield: 11.4, rate: MONTHLY},
    {name: 'STAG Industrial', isin: 'US85254J1025', price: 34.7, distributionYield: 3.95, rate: MONTHLY},
    {name: 'Pembina Pipeline', isin: 'CA7063271034', price: 31.91, distributionYield: 5.78, rate: MONTHLY},

    {name: 'Shell PLC', isin: 'US7802593050', price: 59, distributionYield: 4.05, rate: QUARTAL_3},
    {name: 'Verizon Communications Inc.', isin: 'US92343V1044', price: 37.53, distributionYield: 6.47, rate: QUARTAL_2},
    {name: 'Cisco Systems Inc', isin: 'US17275R1023', price: 44.9, distributionYield: 3.19, rate: QUARTAL_1},

]