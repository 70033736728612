import Stock from "../model/Stock";
import {Card, Tab, Table, Tabs} from "react-bootstrap";
import {Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
// https://recharts.org/en-US/examples/LineBarAreaComposedChart

export default function StockCard({stockData, steps, buyAmount}) {
    const stock = new Stock(stockData, steps, buyAmount);
    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    {stock.name} ({stock.isin})
                </Card.Title>
                <p>Price: {stock.price.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</p>
                <p>Purchase price: {(buyAmount * stock.price).toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                })}</p>
            </Card.Header>
            <Tabs defaultActiveKey={"table"} fill>
                <Tab title={"Table"} eventKey={"table"}>
                    <Table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>New Stock</th>
                            <th>Total Amount</th>
                            <th>Rendite</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {stock.data.map((item, index) => {
                            return (
                                <tr key={index} className={item.sfCheck ? "table-success" : ""}>
                                    <td>{index}</td>
                                    <td>{item.date.toLocaleDateString()} ({item.date.getFullYear() - 1997})</td>
                                    <td>{item.boughtAmount}</td>
                                    <td>{item.amount}</td>
                                    <td style={{color: item.rendite > 0 ? "limegreen" : ""}}>
                                        {item.rendite > 0 ?
                                            <>    {item.rendite > 0 ? '+' : ''}{item.rendite.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}
                                            </> :
                                            <>
                                                - €
                                            </>
                                        }
                                    </td>
                                    <td>{item.total.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Tab>
                <Tab title={"Chart"} eventKey={"chart"}>
                    <ResponsiveContainer width={"100%"}
                                         height={600}
                    >
                        <ComposedChart

                            data={stock.data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="index"/>
                            <YAxis/>
                            <Tooltip/>
                            <Line type="monotone" dataKey="price" stroke="#ff7300"/>
                            <Area type="monotone" dataKey="rendite" stroke="#8884d8" fill="#8884d8"/>
                        </ComposedChart>

                    </ResponsiveContainer>
                </Tab>
            </Tabs>
        </Card>
    );
}
