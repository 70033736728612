import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {useEffect, useState} from "react";

const duartions = [
    {name: "Monthly", value: 1},
    {name: "Quartal", value: 3},
    {name: "Half yearly", value: 6},
    {name: "Yearly", value: 12},
]

export default function SubscriptionIndex() {
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        setSubscriptions(localStorage.getItem('subscriptions'));
    }, [localStorage.getItem('subscriptions')]);


    return (
        <>
            <h1>
                Subscriptions
            </h1>
            <Row>
                <Col>
                    <NewSubscription/>

                </Col>
                <Col>
                    {subscriptions !== null ? <>
                        <Table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Duration</th>
                            </tr>
                            </thead>
                            <tbody>
                            {JSON.parse(subscriptions).map((subscription, index) => (
                                <tr key={index}>
                                    <td>{subscription.name}</td>
                                    <td>{subscription.price}</td>
                                    <td>{subscription.duration}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </> : ""}
                </Col>
            </Row>
        </>
    );
}

function NewSubscription() {
    const [formName, setFormName] = useState("");
    const [formPrice, setFormPrice] = useState(0);
    const [formDuration, setFormDuration] = useState(1);
    const [formMonthlyCost, setFormMonthlyCost] = useState(0);
    const [filledForm, setFilledForm] = useState(false);

    useEffect(() => {
        if (formName !== "" && formPrice > 0) {
            setFilledForm(false);
        } else {
            setFilledForm(true);
        }
    }, [formName, formPrice]);

    useEffect(() => {
        setFormMonthlyCost(formPrice / formDuration);
    }, [formPrice, formDuration]);

    const handleResetClick = () => {

    };
    const handleCreateClick = () => {
        let gespeichertesArray = JSON.parse(localStorage.getItem('subscriptions'));
        console.log(gespeichertesArray); // ['Element1', 'Element2', 'Element3']
        if (gespeichertesArray === null) {
            gespeichertesArray = [];
        }
        gespeichertesArray.push({name: formName, price: formPrice, duration: formDuration});

        localStorage.setItem('subscriptions', JSON.stringify(gespeichertesArray));

    };
    const handleNameChange = (e) => {
        setFormName(e.target.value);
    };
    const handlePriceChange = (e) => {
        setFormPrice(parseFloat(e.target.value));
    };
    const handleDuarationChange = (e) => {
        setFormDuration(parseInt(e.target.value));
    };


    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    Create new subscription
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Form style={{textAlign: "left"}}>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name of subscription</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" onChange={handleNameChange} value={formName}/>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formPrice">
                                <Form.Label>Price</Form.Label>
                                <Form.Control type="number" step={0.1} placeholder="Enter price" style={{textAlign: "right"}} onChange={handlePriceChange} value={formPrice}/>
                            </Form.Group>

                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formDuration">
                                <Form.Label>Duration</Form.Label>
                                <Form.Select onChange={handleDuarationChange}>
                                    {duartions.map((duration, index) => (
                                        <option key={duration.value} value={duration.value}>{duration.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formIsin">
                        <Form.Label>Monthly cost</Form.Label>
                        <Form.Control style={{textAlign: "right"}} type="number" value={formMonthlyCost} readOnly disabled/>
                    </Form.Group>


                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>
                        <Button variant={"warning"} onClick={handleResetClick}>Reset</Button>
                    </Col>
                    <Col>
                        <Button variant={"success"} onClick={handleCreateClick} disabled={filledForm}>Create</Button>
                    </Col>
                </Row>
            </Card.Footer>

        </Card>
    )
}